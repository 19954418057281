/*
 * @Author: Yoneyy (y.tianyuan)
 * @Date: 2024-01-09 10:20:16
 * @Last Modified by: Yoneyy (y.tianyuan)
 * @Last Modified time: 2024-01-09 10:44:20
 */

const BASE_URL_ENV = {
  TESING: 'https://lmc.dev.api.gonghuolianmeng.com/api_v1',
  PRODUCTION: 'https://www.gonghuolianmeng.com/api_v1',
  DEVELOPMENT: 'https://lmc.dev.api.gonghuolianmeng.com/api_v1',

  // TESING: 'https://www.gonghuolianmeng.com/api_v1',
  // PRODUCTION: 'https://www.gonghuolianmeng.com/api_v1',
  // DEVELOPMENT: 'https://www.gonghuolianmeng.com/api_v1',
}

const { REACT_APP_RUNTINE_ENV: REACT_ENV } = process.env;
const ENV = (process.env.NODE_ENV as string).toLocaleUpperCase();
const RUNTIME_ENV = REACT_ENV && REACT_ENV !== ""
  ? REACT_ENV
  : ENV;
// TODO: 上线前修改
// 正式 测试
// export const BASE_URL = BASE_URL_ENV[RUNTIME_ENV as keyof typeof BASE_URL_ENV]
// 本地开发
export const BASE_URL = 'https://lmc.dev.api.gonghuolianmeng.com/api_v1';

export const APP_KEY = '71632d6c6d63616e672d3031313932303430';

const IFRAME_BASE_URL_ENV = {
  TESING: 'http://localhost:3008',
  PRODUCTION: '',
  DEVELOPMENT: 'http://localhost:3008',
}

export const IFRAME_BASE_URL = IFRAME_BASE_URL_ENV[ENV as keyof typeof IFRAME_BASE_URL_ENV]

