import { lazy } from 'react';
// 商家商品管理
export const MerList= lazy(() => import('./mer-list'));
//仓库商品管理
export const SupList = lazy(() => import('./sup-list'));
//商家自动分配
export const MerAutomatic = lazy(() => import('./mer-automatic'));
//商家自动分配
export const SupAddList = lazy(() => import('./sup-addlist'));
//商家采购入库单
export const PurchaseInventory = lazy(() => import('./purchase-inventory'));
